define("admin-client/initializers/setup-pikaday-i18n", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    _moment.default.locale('es');
    let i18n = Ember.Object.extend({
      previousMonth: 'Anterior',
      nextMonth: 'Siguiente',
      months: _moment.default.localeData().months(),
      weekdays: _moment.default.localeData().weekdays(),
      weekdaysShort: _moment.default.localeData().weekdaysShort()
    });
    application.register('pikaday-i18n:main', i18n, {
      singleton: true
    });
    application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});