define("admin-client/templates/select-place", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "elAWc8Ya",
    "block": "{\"symbols\":[\"place\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"select-place-container layout-column layout-align-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-33 flex-offset-33\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"Seleccionar obra\"],[9],[0,\"\\n      \"],[5,\"paper-select\",[],[[\"@label\",\"@options\",\"@onChange\",\"@selected\"],[\"Obra\",[23,0,[\"model\"]],[28,\"fn\",[[23,0,[\"updatePlace\"]]],null],[23,0,[\"selectedPlace\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n      \"],[5,\"paper-button\",[[3,\"on\",[\"click\",[23,0,[\"savePlace\"]]]]],[[\"@raised\",\"@primary\"],[true,true]],{\"statements\":[[0,\"\\n        Seleccionar\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin-client/templates/select-place.hbs"
    }
  });
  _exports.default = _default;
});