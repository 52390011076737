define("admin-client/templates/components/wsk-hamburger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PFh8Turt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"material-hamburger\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"material-hamburger__icon\"],[8],[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",\"material-hamburger__layer\"],[3,\"bind-attr\",null,[[\"class\"],[[24,[\"hamburgerClassSwapper\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin-client/templates/components/wsk-hamburger.hbs"
    }
  });
  _exports.default = _default;
});