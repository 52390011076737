define("admin-client/controllers/index", ["exports", "moment", "exceljs", "file-saver", "firebase/compat/firestore", "firebase/compat/app"], function (_exports, _moment, _exceljs, _fileSaver, _firestore, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let IndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember.computed('fields.[]'), _dec8 = Ember.computed('sortedFields'), _dec9 = Ember.computed('sortedFields'), _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed('sortedFields', 'normalizedRecords'), _dec13 = Ember.computed('sortedFields', 'normalizedRecords'), _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, (_class = class IndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "firebaseApp", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "places", _descriptor4, this);
      _initializerDefineProperty(this, "customer", _descriptor5, this);
      _initializerDefineProperty(this, "fields", _descriptor6, this);
      _initializerDefineProperty(this, "records", _descriptor7, this);
      _initializerDefineProperty(this, "normalizedRecords", _descriptor8, this);
      _initializerDefineProperty(this, "_start", _descriptor9, this);
      _initializerDefineProperty(this, "_end", _descriptor10, this);
      _defineProperty(this, "endOfToday", (0, _moment.default)().endOf('day').toDate());
      _initializerDefineProperty(this, "loading", _descriptor11, this);
      _initializerDefineProperty(this, "menuOpen", _descriptor12, this);
      _initializerDefineProperty(this, "sorts", _descriptor13, this);
    }
    get sortedFields() {
      return Object.fromEntries(Object.entries(this.fields).sort((_ref, _ref2) => {
        let [, field1] = _ref;
        let [, field2] = _ref2;
        const order1 = field1.configuration.dashboard.columns[0].order ?? 200;
        const order2 = field2.configuration.dashboard.columns[0].order ?? 200;
        return order1 - order2;
      }));
    }
    get displayColumns() {
      return Object.entries(this.sortedFields).map(_ref3 => {
        let [singularSlug, field] = _ref3;
        return {
          name: field.configuration.dashboard.columns[0].title,
          valuePath: singularSlug
        };
      });
    }
    get xlsColumns() {
      return Object.entries(this.sortedFields).map(_ref4 => {
        let [singularSlug, field] = _ref4;
        const column = field.configuration.dashboard.columns[0];
        let xlsColumn = {
          header: column.xls?.title ?? column.title,
          key: singularSlug,
          width: column.xls?.width ?? column.width
        };
        if (column.xls?.style) {
          xlsColumn.style = column.xls.style;
        }
        return xlsColumn;
      });
    }
    get displayRecords() {
      return this._calculateDisplayRecords('app');
    }
    get xlsRecords() {
      return this._calculateDisplayRecords('xls');
    }
    _calculateDisplayRecords(defaultValueSlug) {
      const fields = this.sortedFields;
      return this.normalizedRecords.map(normalizedRecord => {
        return Object.fromEntries(Object.keys(fields).map(singularSlug => {
          const configuration = fields[singularSlug].configuration;
          let defaultValue;
          const columnDefaultValue = configuration.dashboard.columns[0]['default-value'];
          const customDefaultValue = configuration.dashboard.columns[0][defaultValueSlug]?.['default-value'];
          switch (configuration.type) {
            case 'number':
              defaultValue = columnDefaultValue ?? customDefaultValue ?? 0;
              break;
            // eslint-disable-next-line no-fallthrough
            case 'timestamp':
            case 'string':
            case 'reference':
            case 'geopoint':
              defaultValue = columnDefaultValue ?? customDefaultValue ?? '';
              break;
            // eslint-disable-next-line no-fallthrough
            case 'object':
              defaultValue = columnDefaultValue ?? customDefaultValue ?? {};
              break;
            // eslint-disable-next-line no-fallthrough
            case 'array':
              defaultValue = columnDefaultValue ?? customDefaultValue ?? [];
              break;
          }
          let value = normalizedRecord[singularSlug];
          if (value === null || value === undefined) {
            value = defaultValue;
          }
          return [singularSlug, value];
        }));
      });
    }
    get start() {
      return this._start.toDate();
    }
    get end() {
      return this._end.toDate();
    }
    set start(date) {
      this._start = (0, _moment.default)(date).startOf('day');
    }
    set end(date) {
      this._end = (0, _moment.default)(date).endOf('day');
    }
    selectStart(date) {
      this.start = date;
    }
    selectEnd(date) {
      this.end = date;
    }
    async logout() {
      await this.session.invalidate();
      this.places.clearSelectedPlace();
      this.router.transitionTo('login');
    }
    async load() {
      this.loading = true;
      await this.loadConfiguration();
      await this.loadRecords();
      this.loading = false;
    }
    async loadConfiguration() {
      const trip = await this.firebaseApp.firestore.doc(`customers/${this.customer.id}/places/${this.places.selectedPlace.id}/`).get();
      this.fields = trip.data()['fields'];
    }
    async loadRecords() {
      const firestore = await this.firebaseApp.firestore;
      const trips = await firestore.collection(`customers/${this.customer.id}/places/${this.places.selectedPlace.id}/trips`).where('time', '>=', this.start).where('time', '<=', this.end).orderBy('time', 'asc').get();
      this.records = trips;
      this.normalizedRecords = await Ember.RSVP.all(trips.docs.map(this._convertRecord.bind(this)), 'Convert firebase records to object');
    }
    async _convertRecord(record) {
      let fields = this.fields;
      const referenceFields = Object.entries(fields).filter(_ref5 => {
        let [, value] = _ref5;
        return value.configuration.type === 'reference';
      });
      const referenceFieldsKeys = referenceFields.map(entry => entry[0]);
      const recordData = record.data();
      const recordEntries = Object.entries(recordData).sort((_ref6, _ref7) => {
        let [key1] = _ref6;
        let [key2] = _ref7;
        const order1 = fields[key1]?.configuration?.dashboard?.columns[0].order ?? 200;
        const order2 = fields[key2]?.configuration?.dashboard?.columns[0].order ?? 200;
        return order1 - order2;
      });
      recordEntries.push(["id", record.id]);
      return Object.fromEntries(await Ember.RSVP.all(recordEntries.map(async _ref8 => {
        let [key, value] = _ref8;
        const singularSlug = key;
        if (referenceFieldsKeys.includes(singularSlug)) {
          value = recordData[singularSlug] ? await recordData[singularSlug].get() : null;
        } else if (key !== 'id') {
          value = recordData[singularSlug];
        }
        value = getValue(value, fields[singularSlug], singularSlug);
        function getValue(value, configuration, singularSlug) {
          let defaultValue = null;
          if (value === null || value === undefined) {
            return defaultValue;
          }
          if (value?.ref instanceof _app.default.firestore.DocumentReference) {
            const fieldName = fields[singularSlug]?.configuration?.field ?? 'name';
            if (fieldName === 'id') {
              return value.id;
            }
            return value.data()[fieldName];
          }
          if (value instanceof _app.default.firestore.Timestamp) {
            return _moment.default.unix(value.seconds).format(configuration?.dateFormat ?? 'DD/MM/YYYY h:mm a');
          }
          switch (value.constructor.name) {
            case 'Number':
              defaultValue = 0;
            // eslint-disable-next-line no-fallthrough
            case 'String':
              defaultValue = '';
            // eslint-disable-next-line no-fallthrough
            case 'Object':
              defaultValue = {};
            // eslint-disable-next-line no-fallthrough
            case 'Array':
              defaultValue = [];
              return value ?? defaultValue;
            case 'Boolean':
              return value ? 'verdadero' : 'falso';
            default:
              return value;
          }
        }
        if (recordData.void) {
          if (singularSlug === 'receipt') {
            value = `Anulado (${value})`;
          } else {
            value = `-`;
          }
        }
        return [singularSlug, value];
      })));
    }
    downloadXls() {
      const workbook = new _exceljs.default.Workbook();
      const startDate = this._start.format('DD-MM-YYYY');
      const endDate = this._end.format('DD-MM-YYYY');
      let title = `Reporte ${this.places.selectedPlace.name} ${startDate} - ${endDate}`;
      const worksheet = workbook.addWorksheet(title);
      worksheet.columns = this.xlsColumns;
      worksheet.addRows(this.xlsRecords);
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        (0, _fileSaver.saveAs)(blob, `${title}.xlsx`);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "places", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fields", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortedFields", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sortedFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayColumns", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "displayColumns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "xlsColumns", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "xlsColumns"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "records", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "normalizedRecords", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "displayRecords", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "displayRecords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "xlsRecords", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "xlsRecords"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_start", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().startOf('day');
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_end", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().endOf('day');
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "menuOpen", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sorts", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        valuePath: 'datetime',
        isAscending: false
      }];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectStart", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "selectStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectEnd", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "selectEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "load", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadRecords", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "loadRecords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadXls", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "downloadXls"), _class.prototype)), _class));
  _exports.default = IndexController;
});