define("admin-client/services/firebase-app", ["exports", "firebase/compat/app", "firebase/compat/auth", "firebase/compat/firestore", "firebase/compat/app-check"], function (_exports, _app, _auth, _firestore, _appCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const firebaseConfig = {
    apiKey: "AIzaSyBxMewHAw1bjSI-k--7-UNf3tBJmUUwc0E",
    authDomain: "construinspector.firebaseapp.com",
    projectId: "construinspector",
    storageBucket: "construinspector.appspot.com",
    messagingSenderId: "684096565975",
    appId: "1:684096565975:web:12a636eaf02eab950df692",
    measurementId: "G-E005PP6PP7"
  };
  const recaptchaKey = '6LdDXUAmAAAAAEORaWaUWHmSHt6zNodLJkBs5E2P';
  class FirebaseAppService extends Ember.Service {
    constructor() {
      super(...arguments);
      _defineProperty(this, "app", void 0);
      _defineProperty(this, "appCheck", void 0);
    }
    async init() {
      super.init();
      this.app = _app.default.initializeApp(firebaseConfig);
      this.appCheck = _app.default.appCheck();
      this.appCheck.activate(new _app.default.appCheck.ReCaptchaEnterpriseProvider(recaptchaKey), true // Set to true to allow auto-refresh.
      );

      if (location.hostname === 'localhost') {
        //const firestore = this.app.firestore();
        //const auth = this.app.auth();
        //firestore.useEmulator('localhost', 6767);
        //auth.useEmulator('http://localhost:9099', {ssl: false});
        //auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      }
    }
    get firestore() {
      return this.app.firestore();
    }
    get auth() {
      return this.app.auth();
    }
  }
  _exports.default = FirebaseAppService;
});